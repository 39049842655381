import { registerApp, Priority } from '@mfl/framework';
import { currentUser } from '@mfl/platform-shell';
import { navigationItems } from '@mfe/core-navigation';
import strings from './settings-strings';

// Repeating the constants for the puspose of avoiding an additional
// runtime bundle for a `settings-types-BVySIyR3.js` (0.82 kB)
const baseRoute = '/v1/settings';
const DOMAIN_MANAGE = 'domain.manage';

export async function register() {
  //when uses is in personal-signatures mode there is no domain with settings
  // as well as if user has no permission
  if (
    currentUser.isAllowedTo(DOMAIN_MANAGE) &&
    currentUser.domainId !== 'personal-signatures'
  ) {
    const SETTINGS_KEY = 'SETTINGS';

    await registerApp('settings', baseRoute);
    navigationItems.register(
      {
        key: SETTINGS_KEY,
        text: strings.settings,
        fontAwesomeIcon: 'gear',
        route: baseRoute + '/domain',
      },
      { priority: Priority.Medium - 110 }
    );
  }
}
